.chat_header{
    bottom: 0;
    right: 2px;
    align-items: center;
    width: 348px;
    height: 15px;
    background-color: var(--fb-theme-colour-chat-toolbar);
    padding: 10px;
    display: flex;
}

.chat_header > .MuiSvgIcon-root{
    color:var(--fb-theme-colour-white);
}

.chat_header.active {
    z-index: 9;
    right: 2px;
    bottom: 0;
    align-items: center;
    width: 348px;
    height: 15px;
    background-color: var(--fb-theme-colour-chat-toolbar);
    display: flex;
}

.toolbar_status_logo {
    padding: 10px;
    display: flex;
    top: 100px;
}

.toobar_enlarger_button {
    display: flex;
    margin-left: auto;
}
.max {
    color:var(--fb-theme-colour-white);
}

.minus {
    color:var(--fb-theme-colour-white);
    margin-top: 7.5px;
}

.minus:hover {
    cursor: pointer;
}

.max:hover {
    cursor: pointer;
}

.status-online {
    height: 12px;
    width: 12px;
    background-color: var(--fb-theme-colour-online);
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

.status-offline {
    height: 12px;
    width: 12px;
    background-color: var(--fb-theme-colour-offline);
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.title {
    color: var(--fb-theme-colour-white);
    text-shadow: 1px 1px 2px, 0 0 25px blue, 0 0 5px darkblue;
    margin-left: 10px;
}
.sidebarrow_container, .people_container{
    display:flex;
    align-items: center;
    padding: 10px;
   
}

.sidebarrow_container:hover {
    background-color: lightgray;
    border-radius: 10px;
}

.sidebarrow_container > h4, .people_container > h4{
    color: var(--fb-theme-colour-text);
    margin-left: 20px;
    font-weight: 600;
}

.person_username {
    text-overflow: ellipsis;
    max-width: 200px;
    width: 150px;
    overflow:hidden;
}

.person_online {
    color: var(--fb-theme-colour-chat-online);
}

.person_offline {
    color: var(--fb-theme-colour-offline);
}

.phone_icon {
    margin-left: auto;
}

.phone_icon:hover {
    cursor: pointer;
}
.sidebarrow_container > .MuiSvgIcon-root, .people_container > .MuiSvgIcon-root{
    font-size: xx-large;
    color: var(--fb-theme-colour-blue);
}

.people_body {
    flex-direction: column; 
    display: inline; 
    margin-top: 10px;
}

.add_friend_button {
    width: 122px;
    margin-left: 36px;
    background-color: var(--fb-theme-colour-blue);
    color: var(--fb-theme-colour-button-hover);
    font-weight: 800;
    padding: 0;
    border: none;
}

.add_friend_button:hover {
    cursor: pointer;
}

.add_friend_button:hover {
    background-color: var(--fb-theme-colour-white);
    color: var(--fb-theme-colour-blue);
}

.add_friend_button > div {
    display: flex;
    justify-content: space-evenly; 
    font-size: 11px;
}

.add_friend_button > div > .MuiSvgIcon-root{
    font-size: 19px;
}

.remove_person_button {
    width: 80px;
    height: 24px;
    margin-left: auto;
    font-weight: 800;
    padding: 0;
    border: var(--fb-theme-colour-remove-friend-border);
    background-color: var(--fb-theme-colour-remove-friend-background);
    display: flex;
    justify-content: center;
    font-size: 11px;
    height: 19px;
    line-height: 16px;
}

.remove_person_button:hover {
    cursor: pointer;
}

.chat_time {
    margin-left: auto;
}

.latest_message_container {
    display: flex;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-left: 20px;
    white-space: nowrap;
}

.latest_message_container > img {
    margin-left: auto;
    margin-right: auto;
    height: 23px;
    width: 28px;
}

.shared_post:hover {
    cursor: pointer;
}

.latest_message {
    display: flex;
    max-width: 130px;
    max-height: 21px;
}

.latest_message > span {
    text-overflow: ellipsis;
    overflow: hidden;
}
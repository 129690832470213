.post_container {
    width:100%;
    margin-top:15px;
    border-radius: 15px;
    background-color: var(--fb-theme-colour-white-posts);
    box-shadow: 0px 5px 7px -7px rgba(0,0,0,0.75);
}

.post_avatar {
    margin-right: 10px;
}

.post_top {
    display:flex;
    position: relative;
    align-items: center;
    padding: 15px;
}

.post_image >img {
    width: 100%;
}

.post_info_top > h3 {
    color: var(--fb-theme-colour-text);
    font-size: medium;
}

.post_info_top > p {
    font-size: small;
    color: var(--fb-theme-colour-grey);
    margin-top: 3px;
}

.post_shared_from:hover {
    cursor: pointer;
}

.menu_icon{
    margin-left: auto; 
}

.menu_icon:hover {
    background-color: var(--fb-theme-colour-button-hover);
    border-radius: 10px;
    color: var(--fb-theme-colour-blue);
    cursor: pointer;
}

.post_bottom {
    color: var(--fb-theme-colour-post-text);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 25px;
}

.post_options {
    padding-top: 10px;
    border-top: 1px solid var(--fb-theme-colour-post-border-top);
    display: flex;
    justify-content: space-evenly;
    font-size: medium;
    color: var(--fb-theme-colour-grey);
    cursor: pointer;
    padding: 15px;
}

.post_option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    flex: 1;
}

.post_option >p {
    margin-left: 10px;
}

.post_option:hover{
    background-color: var(--fb-theme-colour-button-hover);
    border-radius: 10px;
}

.post_option:hover > .MuiSvgIcon-root{
    color: var(--fb-theme-colour-blue);
}

.drop_down {
    height:0; margin:0; padding:0;
}

.messsageContainer {
    height: 0; margin: 0; padding: 0;
}

.messageSender_location {
    display:flex;
    position: fixed;
    margin-top:30px;
    flex-direction: column;
    background-color: var(--fb-theme-colour-white-posts);
    border-radius: 15px;
    border: 1px solid var(--fb-theme-colour-blue);
    box-shadow: 0px 5px 7px -7px rgba(0,0,0,0.75);
    width: 54%;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

 .post_option.active {
    color: var(--fb-theme-colour-blue);
}

.post_option.active:hover > .MuiSvgIcon-root{
    color: var(--fb-theme-colour-blue);
}

.post_arrow.active {
    transform: rotate(180deg)
}
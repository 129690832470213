.displayComments_container {
    background-color: var(--fb-theme-colour-white-posts);
    border-top: 1px var(--fb-theme-colour-post-border-top);
    display: flex;
    margin-bottom: 3px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
}

.displayComments_area {
    display: flex;
    width: fit-content;
    margin-left: 65px;
}

.displayComments_area:hover {
    background-color: var(--fb-theme-colour-button-hover);
}

.display_avatar {
    margin-right: 10px;
    object-fit: contain;
}

.display_username {
    font-size: 15px;
    margin-right: 10px;
    margin-bottom: 0;
    color: var(--fb-theme-colour-post-text);
}

.display_time {
    font-size: small;
    margin-top: 2px;
    color: var(--fb-theme-colour-post-text);
}

.display_menu {
    Display: flex;
}

.display_message {
    color: var(--fb-theme-colour-post-text);
}
.display_menu >p {
    font-size: 10px;
    margin-right: 5px;
    font-weight: bold;
    cursor: pointer;
}

.icon_update {
    color: var(--fb-theme-colour-white);
    background-color: var(--fb-theme-colour-icon-update);
    margin: 4px;
    padding: 8px 16px;
    box-sizing: content-box;
    border-radius: 4px;
    opacity:0.5;
}

.icon_update.active {
    opacity:1;
}

.icon_update:hover {
    cursor:pointer;
}
.call_container {
    border: solid 1px black;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 100;
    height: 500px;
    width: 835px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: var(--fb-theme-colour-white-posts);
    border-radius: 15px;
    box-shadow: 0px 5px 7px -7px rgba(0,0,0,0.75);
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .video_container {
    display:flex;
    flex-direction: row;
  
  }
  
  .button_container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  .video_button > button {
    width: 300px;
    background-color: var(--fb-theme-colour-blue);
    color: var(--fb-theme-colour-button-hover);
    font-weight: 800;
  }
  
  .remote_video_loading {
    position: relative;
    width: 50%;
    float: left;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
.messageSender {
    display:flex;
    margin-top:30px;
    flex-direction: column;
    background-color: var(--fb-theme-colour-white-posts);
    border-radius: 15px;
    box-shadow: 0px 5px 7px -7px rgba(0,0,0,0.75);
    width: 100%;
}

.messageSender__top {
    display:flex;
    border-bottom: 1px solid var(--fb-theme-colour-messenger-sender-border-top);
    padding: 15px;
}

.messageSender__top >form{
    display:flex;
    flex:1;
}

.messageSender__top >form>input{
   outline-width: 0;
   border: none;
   padding: 5px 20px;
   margin: 0 10px;
   border-radius: 999px;
   background-color: var(--fb-theme-colour-textfield);
   color: var(--fb-theme-colour-text);
}

.messageSender__top > form >button {
    display:none;
}

.messageSender__input {
    flex:1;
}

.messageSender__bottom {
    display:flex;
    justify-content: space-evenly;
}

.messageSender__option {
    padding:20px;
    display: flex;
    align-items: center;
    color: var(--fb-theme-colour-post-option);
    margin: 5px;
    left: 5px;
}

.messageSender__text {
    margin-left: 5px;
}

.upload_button {
    cursor: pointer;
    background-color: var(--fb-theme-colour-blue);
    color: var(--fb-theme-colour-button-hover);
    font-weight: 800;
    width: 151px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    text-align: center;
  }
  
  .upload_button:hover {
    background-color: var(--fb-theme-colour-button-hover);
    color: var(--fb-theme-colour-blue);
  }
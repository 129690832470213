.comment_container {
    background-color: var(--fb-theme-colour-white-posts);
    border-top: 1px solid var(--fb-theme-colour-post-border-top);
    display: flex;
    margin-bottom: 3px;
    padding: 10px;
}

.comment_area{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-right: 5px;
}

.comment_avatar {
    margin-right: 10px;
}

.comment_username > h3 {
    font-size: medium;
    margin-right: 15px;
    color: var(--fb-theme-colour-text);
}

.comment_form {
    display:flex;
    flex:1;
}

.comment_form > form {
    flex:1;
    display: flex;
}

.comment_textarea {
    resize: none;
    outline-width: 0;
    border-color: var(--fb-theme-colour-message-background);
    align-items: center;
    background-color: var(--fb-theme-colour-textfield);
    padding: 10px;
    margin-right: 10px;
    flex:1;
}

button {
    padding: 0px 30px;
    padding-top: 50px;
}

.icon {
    color: var(--fb-theme-colour-white);
    background-color: var(--fb-theme-colour-icon-selected);
    opacity: 0.5;
    margin: 4px;
    padding: 8px 16px;
    box-sizing: content-box;
    border-radius: 4px;
}

.icon.active {
    opacity:1;
}

.icon:hover {
    cursor:pointer;
}
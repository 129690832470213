.app_container {
  display:flex;
  max-height: max-content;
  min-height: 100vh;
  background-color: var(--fb-theme-colour-background);
  
}

.app {
  min-width: fit-content;
}
.popup_container {
  border: solid 1px black;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100;
  height: 400px;
  width: 600px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display:flex;
  background-color: var(--fb-theme-colour-white-posts);
  border-radius: 15px;
  box-shadow: 0px 5px 7px -7px rgba(0,0,0,0.75);
  display:flex;
  flex-direction: column;
  }

.popup_title {
  color: var(--fb-theme-colour-grey)
}

.popup_input {
  margin-top: 10px;
  display:flex;
  justify-content: center;
}

.popup_top {
  text-align: center;
  padding: 15px;
}

.popup_inputFile {
  display: none;
}

.popup_label {
  border: 1px solid var(--fb-theme-colour-border);
  display: flex;
  padding: 6px 12px;
  cursor: pointer;
  background-color: var(--fb-theme-colour-blue);
  color: var(--fb-theme-colour-button-hover);
  font-weight: 800;
  width:23%;
}

.popup_label:hover {
  background-color: var(--fb-theme-colour-white);
  color:var(--fb-theme-colour-blue);
}

.popup_preview {
  display: flex;
  justify-content: center;
  height: 270px;
  padding-bottom: 5px;
}

.popup_preview > img{
  display: flex;
  justify-content: center;
  height: 270px;
  width: 541px;
}

.popup_buttons {
  display: flex;
  justify-content: center;
}

.popup_buttons > button {
  cursor: pointer;
  background-color: var(--fb-theme-colour-blue);
  color: var(--fb-theme-colour-button-hover);
  font-weight: 800;
  width: 200px;
  margin-top: 12px;
}

.popup_buttons > button:hover {
  background-color: var(--fb-theme-colour-button-hover);
  color: var(--fb-theme-colour-blue);
}

.popup_divide_buttons {
  padding-left: 5px;
  padding-right:5px;
}
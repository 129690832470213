.login_container > div >img {
    object-fit: contain;
    height: 150px;
}

.login_container > div {
    display:flex;
    flex-direction: column;
}

.login_container {
    display:grid;
    place-items: center;
    height: 100vh;
    background-color: var(--fb-theme-colour-app-background);
}

.login_button {
    display: flex;
    justify-content: center;
}

.login_divider {
    height: 85px;
}

.login_button > button {
    width: 300px;
    background-color: var(--fb-theme-colour-blue);
    color: var(--fb-theme-colour-button-hover);
    font-weight: 800;
    margin-bottom: 85px;
}

.login_button:hover > button {
    background-color: var(--fb-theme-colour-white);
    color: var(--fb-theme-colour-blue);
}

.login_container > button:hover {
    background-color: var(--fb-theme-colour-white);
    color: var(--fb-theme-colour-blue);
}
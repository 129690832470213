.chat_list {
    bottom: 0;
    right: 2px;
    width:367px;
    margin-left: 2px;
    height: 544px;
    background-color:var(--fb-theme-colour-white);
    box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.2); 
    overflow-y:scroll;
    overflow-x: hidden;
    padding-bottom: 5px;
    position: relative;
    display: inline-block;
}

.chat_item, .chat_header_info{
    background: var(--fb-theme-colour-white);
    border-bottom: 1px solid var(--fb-theme-colour-chat-header);
}

.chat_item:hover {
    cursor: pointer;
}

.share_item {
    display: flex;
    justify-content: center;
}
.header {
    display: flex;
    padding: 15px 20px;
    justify-content: space-between; 
    position: sticky;
    background-color: var(--fb-theme-colour-white-header);
    z-index: 100;
    top: 0;
    box-shadow: 0px 5px 8px -9px rgba(0,0,0,0.75);
}

.header_left {
    display: flex;
    justify-content: space-evenly;
}

.header_left > img{
    height: 40px;
}

.header_center {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.header__input {
    display: flex;
    align-items: center;
    background-color: var(--fb-theme-colour-textfield);
    margin-left: 10px;
    border-radius: 999px;
}

.header__input > input {
    border: none;
    background-color: transparent;
    outline-width:0;
}

.header_option > .MuiSvgIcon-root, .notification > .MuiSvgIcon-root{
    color: var(--fb-theme-colour-grey);
}


.header_option:hover > .MuiSvgIcon-root, .notification:hover > .MuiSvgIcon-root{
    color: var(--fb-theme-colour-blue);
}

.header_option.active > .MuiSvgIcon-root{
    color: var(--fb-theme-colour-blue);
}

.header_option.active{
    border-bottom: 4px solid var(--fb-theme-colour-blue);
}

.header_option {
    display:flex;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
}

.notification {
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
}

.notification > span {
    color: var(--fb-theme-colour-notification-text);
    background: var(--fb-theme-colour-notification-background);
    width: fit-content;
    padding: 2px 5px;
    right: 20px;
    top: -1px;
    position: absolute;
}

.header_option:hover, .notification:hover{
    background-color: var(--fb-theme-colour-button-hover);
    border-radius: 10px;
    align-items: center;
    padding: 0 30px;
    border-bottom: none;
}

.header_info {
    display:flex;
    align-items: center;
}

.header_info > h4{
    color:  var(--fb-theme-colour-text);
    margin-left: 10px;
}

.header_right {
    display:flex;
}

.header_arrow.active {
    transform: rotate(180deg)
}

.header_divider {
    display: flex;
    flex: 0.2;
    justify-content: center;
}
.dropDownSignOut_container {
  position:absolute;
  width: 108px;
  height: 23px;
  right: 1%;
  top: 77%;
  background-color: var(--fb-theme-colour-white);
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;
  margin-left:auto;
  display: flex;
  align-items: center;
  z-index: 9;
}
  
li {
  list-style: none;
  font-size: medium;
  color: var(--fb-theme-colour-grey);
  margin-bottom: 5px;
  display: flex;
  align-items: center
}
     
li:hover {
  cursor: pointer;
  background-color: var(--fb-theme-colour-button-hover);
  border-radius: 10px;
  color: var(--fb-theme-colour-blue);
}
     
ul {
  padding:0;
}
  
li > .MuiSvgIcon-root {
  margin-right: 5px;
  display: flex;
  align-items: center;
  font-size: 20px;
}